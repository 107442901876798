import React, { useState } from 'react';
import supabase from './supabaseClient';
import './RSVPForm.css';

const RSVPForm = () => {
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [invited, setInvited] = useState(0);
  const [attending, setAttending] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [rsvpSubmitted, setRsvpSubmitted] = useState(false);

  const handleCodeChange = (e) => {
    setCode(e.target.value.toUpperCase());
    resetForm();
  };

  const resetForm = () => {
    setName('');
    setInvited(0);
    setAttending('');
    setErrorMessage('');
    setConfirmationMessage('');
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    try {
      let { data, error } = await supabase
        .from('RSVP_Bride')
        .select('name, invited')
        .eq('code', code)
        .single();

      if (error || !data) {
        const response = await supabase
          .from('RSVP_Groom')
          .select('name, invited')
          .eq('code', code)
          .single();
        data = response.data;
        error = response.error;
      }

      if (error || !data) {
        setErrorMessage('Invalid code. Please check your invitation and try again.');
      } else {
        setName(data.name);
        setInvited(data.invited);
        setErrorMessage('');
      }
    } catch (err) {
      console.error('Error fetching RSVP data', err);
      setErrorMessage('Error fetching RSVP data. Please try again.');
    }
  };

  const handleAttendingChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setAttending(value);
    setErrorMessage('');
  };

  const handleNoneAttendingClick = () => {
    setAttending(0); // Set the dropdown value to 0 when "None attending" is clicked
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (attending >= 0) {
      try {
        let { error } = await supabase
          .from('RSVP_Bride')
          .update({ attending })
          .eq('code', code);

        if (error) {
          const response = await supabase
            .from('RSVP_Groom')
            .update({ attending })
            .eq('code', code);
          error = response.error;
        }

        if (error) {
          console.error('Error submitting RSVP', error);
          setErrorMessage('Error submitting RSVP.');
        } else {
          setConfirmationMessage(`Thank you - your RSVP for ${attending} ${attending === 1 ? 'person' : 'people'} has been recorded.`);
          setRsvpSubmitted(true);
        }
      } catch (err) {
        console.error('Error:', err);
        setErrorMessage('Error submitting RSVP.');
      }
    } else {
      setErrorMessage('Please select the number of attendees or choose "None attending".');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="rsvp-form">
      {!rsvpSubmitted ? (
        <>
          <div className="form-group">
            <label>Enter 3-Letter Code:</label>
            <input
              type="text"
              value={code}
              onChange={handleCodeChange}
              maxLength="3"
              required
              className="code-input"
            />
            <button onClick={handleCodeSubmit} className="code-submit-button">
              Submit Code
            </button>
          </div>

          {name && (
            <div className="form-group">
              <p className="welcome-message">Welcome, </p>
              <p className="Guest-Name">{name}</p>
              <label>Please select the number of people attending:</label>
              <select
                className="select-dropdown"
                value={attending}
                onChange={handleAttendingChange}
                required
              >
                <option value="">Select number</option>
                <option value="0">0</option>
                {Array.from({ length: invited }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
              <button
                type="button"
                className={`none-attending-button ${attending === 0 ? 'selected' : ''}`}
                onClick={handleNoneAttendingClick}
              >
                None attending
              </button>
            </div>
          )}
          {name && (
            <button type="submit" disabled={attending === ''} className="submit-button">
              Submit RSVP
            </button>
          )}
        </>
      ) : (
        <p className="confirmation-message">{confirmationMessage}</p>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </form>
  );
};

export default RSVPForm;
