import React, { useState, useEffect } from 'react';
import supabase from './supabaseClient';
import './RSVPList.css';

const RSVPList = () => {
  const [rsvpData, setRsvpData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('All'); // 'All', 'Bride', 'Groom'

  // Summary data states
  const [totalInvited, setTotalInvited] = useState(0);
  const [totalInvitationsSent, setTotalInvitationsSent] = useState(0);
  const [totalResponded, setTotalResponded] = useState(0);
  const [totalAttending, setTotalAttending] = useState(0);
  const [totalNotComing, setTotalNotComing] = useState(0);

  const fetchRSVPData = async () => {
    setLoading(true);
    setError(null);

    try {
      let data = [];
      let brideData = [];
      let groomData = [];

      // Fetch bride side data
      const { data: brideSideData, error: brideError } = await supabase
        .from('RSVP_Bride')
        .select('name, invited, attending');

      if (brideError) throw brideError;
      brideData = brideSideData.map(guest => ({ ...guest, side: 'Bride' }));

      // Fetch groom side data
      const { data: groomSideData, error: groomError } = await supabase
        .from('RSVP_Groom')
        .select('name, invited, attending');

      if (groomError) throw groomError;
      groomData = groomSideData.map(guest => ({ ...guest, side: 'Groom' }));

      // Filter data based on the selected tab
      if (filter === 'Bride') {
        data = brideData;
      } else if (filter === 'Groom') {
        data = groomData;
      } else {
        data = [...brideData, ...groomData];
      }

      setRsvpData(data);

      // Calculate summary statistics based on the filtered data
      const invitedTotal = data.reduce((acc, guest) => acc + (guest.invited || 0), 0);
      const invitationsTotal = data.length; // Count of entries in the selected table(s)
      const respondedTotal = data.filter(guest => guest.attending !== null).length;
      const attendingTotal = data.reduce((acc, guest) => acc + (guest.attending || 0), 0);
      const notComingTotal = data.reduce((acc, guest) => {
        if (guest.attending !== null && guest.attending < guest.invited) {
          return acc + (guest.invited - guest.attending);
        }
        return acc;
      }, 0);

      setTotalInvited(invitedTotal);
      setTotalInvitationsSent(invitationsTotal);
      setTotalResponded(respondedTotal);
      setTotalAttending(attendingTotal);
      setTotalNotComing(notComingTotal);

    } catch (err) {
      setError('Error fetching RSVP data');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRSVPData();
  }, [filter]);

  return (
    <div className="rsvp-list">
      <h2 className="rsvp-title">RSVP List</h2>

      {/* RSVP Summary */}
      <div className="rsvp-summary">
        {/* <div className="summary-box invitations-sent">
          <p><strong>Invitations Sent</strong></p>
          <p>{totalInvitationsSent}</p>
        </div>
        <div className="summary-box total-invited">
          <p><strong>No. of People Invited</strong></p>
          <p>{totalInvited}</p>
        </div> */}
        <div className="summary-box total-responded">
          <p><strong>Invitation Responses</strong></p>
          <p>{totalResponded}/{totalInvitationsSent}</p>
        </div>
        <div className="summary-box total-attending">
          <p><strong>No. of People Attending</strong></p>
          <p>{totalAttending}/{totalInvited}</p>
        </div>
        <div className="summary-box total-not-coming">
          <p><strong>No. of People Not Coming</strong></p>
          <p>{totalNotComing}</p>
        </div>
      </div>

      {/* Filter Buttons */}
      <div className="filter-buttons">
        <button
          className={filter === 'All' ? 'active' : ''}
          onClick={() => setFilter('All')}
        >
          All
        </button>
        <button
          className={filter === 'Bride' ? 'active' : ''}
          onClick={() => setFilter('Bride')}
        >
          Bride's Side
        </button>
        <button
          className={filter === 'Groom' ? 'active' : ''}
          onClick={() => setFilter('Groom')}
        >
          Groom's Side
        </button>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {!loading && !error && (
        <table className="rsvp-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Invited</th>
              <th>Attending</th>
            </tr>
          </thead>
          <tbody>
            {rsvpData.map((guest, index) => (
              <tr key={index} className={`row-${guest.side.toLowerCase()} ${guest.attending !== null ? 'responded' : 'not-responded'}`}>
                <td>{guest.name}</td>
                <td>{guest.invited}</td>
                <td>{guest.attending !== null ? guest.attending : 'Not RSVPed'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default RSVPList;
