// Admin.js
import React, { useState } from 'react';
import RSVPList from './RSVPList';
import AddGuest from './AddGuest';
import './Admin.css';

const Admin = () => {
  const [currentTab, setCurrentTab] = useState('rsvp-list');
  const [currentSide, setCurrentSide] = useState('groom');

  const renderTabContent = () => {
    if (currentTab === 'rsvp-list') {
      return <RSVPList side={currentSide} />;
    }
    if (currentTab === 'add-guest') {
      return <AddGuest />;
    }
    return null;
  };

  return (
    <div className="admin">
      <nav className="admin-nav-tabs">
        <button
          className={`nav-button ${currentTab === 'rsvp-list' ? 'active' : ''}`}
          onClick={() => setCurrentTab('rsvp-list')}
        >
          RSVP List
        </button>
        <button
          className={`nav-button ${currentTab === 'add-guest' ? 'active' : ''}`}
          onClick={() => setCurrentTab('add-guest')}
        >
          Add Guests
        </button>
      </nav>
      {/* {currentTab === 'rsvp-list' && (
        <div className="side-selector">
          <button
            className={`side-button ${currentSide === 'groom' ? 'active' : ''}`}
            onClick={() => setCurrentSide('groom')}
          >
            Groom'csadsds Side
          </button>
          <button
            className={`side-button ${currentSide === 'bride' ? 'active' : ''}`}
            onClick={() => setCurrentSide('bride')}
          >
            Bride's Side
          </button>
        </div>
      )} */}
      <div className="admin-content">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default Admin;
