import React, { useState } from 'react';
import supabase from './supabaseClient';
import './AddGuest.css';

const AddGuest = () => {
  const [name, setName] = useState('');
  const [invited, setInvited] = useState('');
  const [code, setCode] = useState('');
  const [tableName, setTableName] = useState('RSVP_Bride'); // Default to Bride's side
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate input fields
    if (!name || !invited || !code) {
      setMessage('Please fill in all fields.');
      return;
    }

    const invitedCount = parseInt(invited, 10);
    if (isNaN(invitedCount) || invitedCount <= 0) {
      setMessage('Please enter a valid number for people invited.');
      return;
    }

    try {
      // Check for unique code across both tables
      const { data: brideData, error: brideError } = await supabase
        .from('RSVP_Bride')
        .select('code')
        .eq('code', code.toUpperCase());

      const { data: groomData, error: groomError } = await supabase
        .from('RSVP_Groom')
        .select('code')
        .eq('code', code.toUpperCase());

      if (brideError || groomError) {
        console.error('Error checking code uniqueness:', brideError || groomError);
        setMessage('Error checking code uniqueness.');
        return;
      }

      if (brideData.length > 0 || groomData.length > 0) {
        setMessage('The code already exists. Please enter a unique code.');
        return;
      }

      // Insert guest into selected table
      const { data, error } = await supabase
        .from(tableName)
        .insert([{ name, invited: invitedCount, code: code.toUpperCase(), attending: null }]);

      if (error) {
        console.error('Error adding guest to', tableName, error); // Log error details
        setMessage('Error adding guest. Please try again.');
      } else {
        setMessage(`Guest added successfully to ${tableName}.`);
        setName('');
        setInvited('');
        setCode('');
      }
    } catch (err) {
      console.error('Unexpected error:', err);
      setMessage('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div className="add-guest">
      <h2>Add Guests</h2>
      <form onSubmit={handleSubmit} className="add-guest-form">
        <div className="form-group">
          <label>Name on Invitation:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Number of People Invited:</label>
          <input
            type="number"
            value={invited}
            onChange={(e) => setInvited(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>3-Letter Code:</label>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            maxLength="3"
            required
          />
        </div>
        <div className="form-group">
          <label>Select Side:</label>
          <select value={tableName} onChange={(e) => setTableName(e.target.value)} required>
            <option value="RSVP_Bride">Nasha</option>
            <option value="RSVP_Groom">AM</option>
          </select>
        </div>
        <button type="submit" className="submit-button">Add Guest</button>
      </form>
      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default AddGuest;
